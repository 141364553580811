/**
 * @desc App View
 *
 *
 */
define('views/booking-item',[

  'jquery',
  'underscore',
  'backbone',
  'utils',
  'bootstrap'

],  function($, _, Backbone, Utils, Bootstrap){

  return Backbone.View.extend({

    // setup DOM Elements

    // bind Events
    events: {
      'click a': 'bookingItemSelected',
    },

    /**
     * initialize()
     * @desc initialize the view
     *
     * @param options
     */
    initialize: function(options){
    },

    bookingItemSelected: function(ev) {
      console.log(ev);
    },

    /**
     * render()
     * @desc Render the view
     *
     *
     */
    render: function(model){
      return _.template(
        '<li data-timestamp="'+ model.timestamp +'">' +
          '<a class="booking-day">' +
            '<div>' +
              '<span class="day">'+ model.day +'</span>' +
              '<span class="date">'+ model.date +'</span>' +
            '</div>' +
          '</a>' +
          '<div class="date-times">' +
            '<a data-time="1">LUNCH</a>' +
            '<a data-time="2">DINNER</a>' +
          '</div>' +
        '</li>'
      );
    }
  });
});

