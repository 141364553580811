/**
 * @desc App View
 *
 *
 */
define('views/app',[

  'jquery',
  'underscore',
  'backbone',
  'utils',
  'bootstrap',
  './booking'

],  function($, _, Backbone, Utils, Bootstrap, Booking){

  return Backbone.View.extend({

    // setup DOM Elements
    el : $('body'),

    // bind Events
    events: {
      'click #nav-item-gifts': 'onGiftClickHandler',
      'hidden.bs.modal #gift-card-modal': 'onHiddenModal',
      'submit form#contact': 'onFormSubmit'
    },

    /**
     * initialize()
     * @desc initialize the view
     *
     * @param options
     */
    initialize: function(options){
      console.log('App View');
      $('form#contact [type="submit"]').attr('disabled', false);

      // show active tab on reload
      if (location.hash !== '') {
        setTimeout(function() {
          window.scrollTo(0, 0);
        }, 1);

        $('a[href="' + location.hash + '"]').tab('show');
      }

      $('#takeout').on('click', function(){
        setTimeout(function() {
          window.scrollTo(0, 0);
        }, 1);
        $('a[href="#take_out"]').tab('show');
      });

      var booking = new Booking();

      // remember the hash in the url without jumping
      $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
         if(history.pushState) {
              history.pushState(null, null, '#'+$(e.target).attr('href').substr(1));
         } else {
              location.hash = '#'+$(e.target).attr('href').substr(1);
         }
      });

    },

    onGiftClickHandler: function(ev){
      ev.preventDefault();
      $('#gift-card-modal').modal('show');
    },

    onFormSubmit: function(ev){
      ev.preventDefault();

      var $button = $('form#contact [type="submit"]');

      var form = ev.target;
      var valid = true;

      if($('#name').val() == '') {
        valid = false;
        console.log('invalid name');
      }

      if($('#phone').val() == '') {
        valid = false;
        console.log('invalid phone');
      }

      if($('#email').val() == '') {
        valid = false;
        console.log('invalid email');
      }

      if(valid){
        $button.html('<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>');
        $('#messageBox').html('');
        this.submitForm(form);
      } else {
        $('#messageBox').addClass('invalid').html('Please fill out all fields.');
      }

    },

    submitForm: function(form){
      var self = this;
      var $form = $(form);
      var fields = $form.serialize();

      $.ajax({
        type: form.method,
        url: form.action,
        data: fields,
        success: function(data) {
          self.formSuccess();
        },
        error: function(data) {
          self.formError();
        }
      });
    },

    formSuccess: function(){
      this.successSubmitButton();
      $('#messageBox').removeClass('invalid').addClass('valid').html('Success! We\'ll be in touch shortly.');
    },

    formError: function(){
      this.resetSubmitButton();
      $('#messageBox').removeClass('valid').addClass('invalid').html('Error. Please try again.');
    },

    resetSubmitButton: function(){
      var $button = $('form#contact [type="submit"]');
      $button.html($button.attr('data-default'));
    },

    successSubmitButton: function(){
      var $button = $('form#contact [type="submit"]');
      $button.html('<i class="fa fa-check-circle-o fa-2x"></i>');
    },

    /**
     * render()
     * @desc Render the view
     *
     *
     */
    render: function(){
    	// No render
    }
  });
});

