/**
 * @desc App View
 *
 *
 */
define('views/booking',[

  'jquery',
  'underscore',
  'backbone',
  'utils',
  'bootstrap',
  'flickity',
  './booking-item'

],  function($, _, Backbone, Utils, Bootstrap, Flickity, BookingItem){

  return Backbone.View.extend({

    // setup DOM Elements
    el : $('#booking-list'),

    // bind Events
    events: {

    },

    /**
     * initialize()
     * @desc initialize the view
     *
     * @param options
     */
    initialize: function(options){
      console.log('Booking View');
      var date = new Date();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();

      var currentMonth = this.trimPastFromDaysArray(this.getDaysArray(year, month));
      var nextMonth = month === 12 ? this.getDaysArray(year + 1, 1) : this.getDaysArray(year, month + 1);
      var bookingDays = currentMonth.concat(nextMonth);

      var BookingDays = new Backbone.Collection(bookingDays);

      this.render(BookingDays);
    },

    getDaysArray: function(year, month) {
      var names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
      var date = new Date(year, month - 1, 1);
      var result = [];
      while (date.getMonth() == month - 1) {
        date.setDate(date.getDate() + 1);
        var day = {
          timestamp: date.getTime(),
          date: ("0" + date.getDate()).slice(-2),
          day: names[date.getDay()]
        }
        result.push(day);
      }
      return result;
    },

    trimPastFromDaysArray: function(days) {
      var date = new Date().getDate();
      return days.slice(date - 1);
    },

    /**
     * render()
     * @desc Render the view
     *
     *
     */
    render: function(collection){
      var self = this;
      var list = '';
      _.each(collection.models, function(model){
        self.$el.append(new BookingItem().render(model.attributes));
      });

      // element argument can be a selector string
      //   for an individual element
      var flkty = new Flickity( '#booking-list', {
        cellAlign: 'left',
        groupCells: '90%',
        pageDots: false,
        on: {
          staticClick: function(ev, s, el) {
            console.log();
            if($(ev.target).data('time')) {
              var names = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
              const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
              ];
              // a time was selected
              $('a[data-time]').removeClass('selected');
              $(ev.target).addClass('selected');

              var timestamp = $(ev.target).parents('[data-timestamp]').data('timestamp');
              var date = new Date(timestamp);
              var day = names[date.getDay()];
              var month = monthNames[date.getMonth()];
              var dateDay = date.getDate();
              var time = $(ev.target).html();
              console.log(date,time)

              // insert selected time into UI
              $('.booking-confirmation h2').html(day + ', ' + month + ' ' + dateDay);
              $('.booking-confirmation .confirm-time .time-data').html(time);

              // insert time into mailto:
              var mailToSchema = $('.mail-to-schema').html();
              console.log(mailToSchema);
              $('.confirm-submit').attr('href', mailToSchema.replace('*DAY*', day + ', ' + month + ' ' + dateDay).replace('*TIME*', time).replace('&amp;', '&'));

              $('.booking-confirmation').addClass('active');

              // a day was selected
            } else {
              $(el).toggleClass('active');
            }
          }
        }
      });


    }
  });
});

